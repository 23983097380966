<template>
	<div>
		<!-- top image bar -->
		<Header :propsObj="page"></Header>

		<!-- at home - logo button - at school -->
		<HeaderBar></HeaderBar>

		<!-- introduction -->
		<Intro :title="page.intro_title" :content="page.intro_content"></Intro>

		<!-- content -->
		<!-- <div class="container">
			<div class="row">
				<div class="col-9">
					<BtnImage
						bgColor="" aspect="3"
					></BtnImage>
				</div>
			</div>
		</div>-->

		<!-- content -->
		<div class="container mt-5">
			<div class="row">
				<!-- left part with rows -->
				<div class="col-md-9">
					<div v-for="q in quarks" :key="q.id">
						<div class="row">
							<div v-for="(c, index) in q.nrCols" :key="index" class="col-12">
								<p
									v-if="q.titleCol == index"
									class="larger fg-pink text-uppercase font-weight-bold mb-3"
									v-html="q.title"
								></p>
							</div>
						</div>
						<div class="row mb-3" :class="{ qt: q.config == 1 }">
							<div
								v-for="(c, index) in q.nrCols"
								:key="index"
								:class="q.colStyle[index]"
								v-html="q.columns[index]"
							></div>
						</div>
					</div>
				</div>

				<!-- right sidebar -->
				<div class="col-md-3">
					<Avatar :content="page.avatar"></Avatar>
					<BtnDownloads></BtnDownloads>
					<BtnImage
						:bgColor="sideButton.bgColor"
						:title="sideButton.title"
						:content="sideButton.content"
						:image="sideButton.image"
						:link="sideButton.link"
					></BtnImage>
					<BtnReferences></BtnReferences>
					<SocialMedia></SocialMedia>
				</div>
			</div>
		</div>

		<!-- footer  -->
		<Footer></Footer>
	</div>
</template>

<script>
import utils from '@/js/utils';

import Avatar from '@/components/Avatar.vue';
import BtnDownloads from '@/components/BtnDownloads.vue';
import BtnReferences from '@/components/BtnReferences.vue';
import BtnImage from '@/components/BtnImage.vue';
import Header from '@/components/Header.vue';
import HeaderBar from '@/components/HeaderBar.vue';
import Intro from '@/components/Intro.vue';
import SocialMedia from '@/components/SocialMedia.vue';
import Footer from '@/components/Footer.vue';

export default {
	data() {
		return {
			avatar: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt',
			sideButton: {
				bgColor: '',
				title: 'blog',
				content: 'Mijn kennis en bijzondere ervaringen - die deel ik graag met jou!',
				image: '/uploads/pencils.jpg',
				link: '/blog',
			},
		};
	},

	props: {
		route: String,
	},

	components: {
		Avatar,
		BtnDownloads,
		BtnReferences,
		BtnImage,
		Header,
		HeaderBar,
		Intro,
		SocialMedia,
		Footer,
	},

	computed: {
		quarks() {
			let qq = this.$store.getters.quarks;
			// console.log('main.vue computed quarks', quarks.length);
			if (qq.length > 0) {
				this.handleQuarks(qq);
				// console.log(qq);
				return qq;
			}
		},
		page() {
			let pp = this.$store.getters.page(this.route);
			if (pp.header_image == 'afbeelding') {
				pp.header_image = '../uploads/blokken.jpg';
			}
			return pp;
		},
	},

	created: function () {
		console.clear();
		var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -5).replace('T', ' ');
		console.log('main', localISOTime);
		// this.page = this.$store.getters.page(this.route);
		// console.log('page', this.route, this.page);
	},

	methods: {
		handleQuarks(arr) {
			// console.log('arr', arr.length, arr);
			utils.sortByRank(arr);

			arr.forEach((q) => {
				// columns
				// if (q.cols == null || q.cols == '') {
				// 	q.cols = ['12'];
				// } else {
				// 	q.cols = utils.strip_tags(q.cols);
				// 	q.cols = q.cols.split(';');
				// }

				// console.log('q', q);

				// determine which column title belongs to
				if (q.title != null) {
					let spl = q.title.split(';');
					if (spl.length == 1) {
						q.titleCol = 0;
					} else {
						q.title = spl[0];
						q.titleCol = spl[1];
					}
				}

				// create column styles
				if (!Array.isArray(q.cols)) q.cols = q.cols.split(';');
				q.colStyle = [];
				let i = 0;
				q.cols.forEach((style) => {
					q.colStyle[i++] = 'col-md-' + style;
				});

				// console.log('cols', q.cols);
				// console.log(q.colStyle);

				// let arr = q.cols.split(';');
				q.nrCols = q.cols.length;

				q.columns = [];
				q.columns.push(q.col1);
				q.columns.push(q.col2);
				q.columns.push(q.col3);
				q.columns.push(q.col4);

				// check for image
				for (let i = 0; i < q.columns.length; i++) {
					// console.log('el', el);
					let el = q.columns[i];
					if (el != null) {
						if (el.indexOf('<img') != -1) {
							// console.log('img-fluid1', q.columns[i]);
							let a = utils.strip_tags(el, '<a>');
							let img = utils.strip_tags(el, '<img>');

							// keep link intact
							if (a.indexOf('<a href') == -1) {
								q.columns[i] = utils.img_fluid(img);
							} else {
								q.columns[i] = a.replace('</a>', utils.img_fluid(img) + '</a>');
							}
							// console.log('img-fluid2', q.columns[i]);
						}
					}
				}
			});
		},
	},
};
</script>

<style>
.larger {
	font-size: 19px;
}

.qt {
	color: white;
	background-color: #f39200;
}

.divv {
	display: table;
	height: 100%;
	width: 100%;
	text-align: center;
	border: 2px dashed #f69c55;
}
span {
	display: table-cell;
	vertical-align: middle;
}
</style>
